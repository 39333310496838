import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {Helmet} from 'react-helmet'
import Layout from '../components/Layout/Layout'


export default class AgreementSigned extends React.Component {
    render () {
        return <Layout>
            <Container className="mt-5 mb-5">
                <Helmet>
                    <meta name="robots" content="noindex"/>
                </Helmet>

                <Row>
                    <Col xs="12">
                        <h1>Thank you!</h1>
                        <p>We will start processing your claim shortly.</p>

                    </Col>

                </Row>
            </Container>
        </Layout>
    }
}